import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Loader from "react-loader-spinner";

import '../../../assets/css/font.css';

import banner from "../../../assets/img/company/companyBanner.png";
import logo01 from "../../../assets/img/company/logo01.png";
import logo02 from "../../../assets/img/company/logo02.png";
import logo03 from "../../../assets/img/company/logo03.png";
import logo04 from "../../../assets/img/company/logo04.png";
import logo05 from "../../../assets/img/company/logo05.png";
import logo06 from "../../../assets/img/company/logo06.png";
import logo07 from "../../../assets/img/company/logo07.png";
import logo08 from "../../../assets/img/company/logo08.png";
import logo09 from "../../../assets/img/company/logo09.png";
import logo10 from "../../../assets/img/company/logo10.png";

const Company = (oProps) => {
    const [isLoading, setLoading] = useState(true);
    const [infoList, setInfoList] = useState([]);

    const checkInfo = async () => {
        const oResponse = await oProps.oManager.fDoAxios("/home/getThrooArticle", "get", null, null);
        if (oResponse !== undefined) {
            if (oResponse.length > 0) {
                setInfoList(oResponse);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        checkInfo();
        document.title = "회사소개";
        document.querySelector('meta[name="keywords"]').setAttribute("content", '인비저블아이디어, 스루');
        document.querySelector('meta[name="description"]').setAttribute("content", '스루의 비전, 인비저블아이디어 소개.');
        document.querySelector('link[rel="canonical"]').setAttribute("href", 'https://throo.co.kr/company');
        document.querySelector('meta[property="og:title"]').setAttribute("content", '회사소개');
        document.querySelector('meta[property="og:description"]').setAttribute("content", '스루의 비전, 인비저블아이디어 소개.');
    }, []);

    return (
        <Style>
            <div className="companyPage">
                <div className="cPage">
                    <img src={banner} alt="회사소개 메인사진" width="100%" style={{ maxHeight: "520px" }} />
                    <div className="container">
                        <div className="content">
                            <h2>INVISIBLE IDEAS</h2>
                            <p>
                                인비저블 아이디어는 일상생활에서 반복되는 사소한 불편함을 새로운 아이디어로 해결하고자 합니다.<br />
                                작은 변화를 통해 세상의 큰 변화를 이끄는 서비스를 만들기 위해 항상 노력하겠습니다.<br /><br />
                                에너지와 비용을 아껴 가치를 만들고 그 가치로 소비자와 판매자 모두에게 이로운 서비스를 만들겠습니다.
                            </p>
                        </div>
                        <div className="content">
                            <h2>THROO</h2>
                            <p>
                                스루는 다양한 매장을 워크스루와 드라이브스루로 이용 가능한 앱 서비스입니다.<br />
                                기존 포장주문에 드라이브스루 픽업을 더해 운전자의 관점에서 바라보며 실시간 위치 기반 기술과 도착<br />
                                시간 예측, 운전자의 주행 방향에 최적화된 매장 안내 등 사용자에게 특화된 서비스를 구현합니다.<br /><br />

                                새로운 드라이브스루의 시작, 스루가 함께하겠습니다.
                            </p>
                        </div>
                        <div className="content">
                            <h2>HISTORY<br />OF INVISIBLE IDEAS</h2>
                            <div className="history">
                                <div className="hContent">
                                    <h3>2023년</h3>
                                    <div className="hText">
                                        <ul>
                                            <li>2023.03</li>
                                            <li>스마트상점 기술공급기업 선정</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="hContent">
                                    <h3>2022년</h3>
                                    <div className="hText">
                                        <ul>
                                            <li>2022.07</li>
                                            <li>IBK창공 구로 8기 선정</li>
                                        </ul>
                                        <ul>
                                            <li>2022.06</li>
                                            <li>TIPS 선정</li>
                                            <li>기보캠프 10기 우수 졸업기업 선정</li>
                                        </ul>
                                        <ul>
                                            <li>2022.05</li>
                                            <li>신한 스퀘어브릿지 대구 1기 선정</li>
                                            <li>중소벤처기업부 창업진흥원 초창패 선정</li>
                                            <li>파이서브코리아(유) 업무협약 체결</li>
                                        </ul>
                                        <ul>
                                            <li>2022.04</li>
                                            <li>인라이트벤처스(유) 투자유치</li>
                                        </ul>
                                        <ul>
                                            <li>2022.02</li>
                                            <li>D.CAMP D.DAY 선정</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="hContent">
                                    <h3>2021년</h3>
                                    <div className="hText">
                                        <ul>
                                            <li>2021.12</li>
                                            <li>(주)디딤푸드, (주)놀부 등 38개 브랜드 업무협약</li>
                                        </ul>
                                        <ul>
                                            <li>2021.10</li>
                                            <li>노량진 수산 상인회 업무협약</li>
                                        </ul>
                                        <ul>
                                            <li>2021.09</li>
                                            <li>중소 벤처기업부 R<span>&#38;</span>D 사업 선정</li>
                                        </ul>
                                        <ul>
                                            <li>2021.07</li>
                                            <li>삼성 C-Lab 아웃사이드 12기 선정</li>
                                            <li>스루 앱 베타버전 출시</li>
                                        </ul>
                                        <ul>
                                            <li>2021.02</li>
                                            <li>현대기아자동차 POC</li>
                                        </ul>
                                        <ul>
                                            <li>2021.01</li>
                                            <li>스루 프로토 타입 출시</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="hContent">
                                    <h3>2020년</h3>
                                    <div className="hText">

                                        <ul>
                                            <li>2020.02</li>
                                            <li>법인설립</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content" style={{ marginTop: "170px" }}>
                            <h2>NEWS</h2>
                            <div className="newsContent">
                                {isLoading ?
                                    <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                                    </div>
                                    :
                                    <>
                                        {infoList.map((item, index) => {
                                            return (
                                                <div key={index} className="news">
                                                    <a href={item.article} target="_blank">
                                                        <div className="nContent">
                                                            <div className="title">
                                                                <h3>{item.subTitle}</h3>
                                                                <p>{item.date}</p>
                                                            </div>
                                                            <h2>{item.title}</h2>
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        })

                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className="content">
                            <h2>PARTNERSHIP</h2>
                            <div className="logoContent">
                                <div className="logo">
                                    <img src={logo01} alt="현대자동차로고" width="184px" height="26px" />
                                    <img src={logo02} alt="삼성전자로고" width="136px" height="42px" />
                                    <img src={logo04} alt="중소벤처기업부로고" width="159px" height="36px" />
                                    <img src={logo10} alt="IBK창공로고" width="137px" height="88px" />
                                </div>
                                <div className="logo">
                                    <img src={logo05} alt="디캠프로고" width="168px" height="56px" />
                                    <img src={logo06} alt="신한스퀘어브릿지대구로고" width="136px" height="42px" />
                                    <img src={logo07} alt="팁스로고" width="98px" height="98px" />
                                    <img src={logo08} alt="파이서브코리아로고" width="105px" height="52px" />
                                </div>
                                <div className="logo">
                                    <img src={logo09} alt="기보캠프로고" width="158px" height="77px" />

                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <h2>CONTACT</h2>
                            <div className="contact">
                                <div className="contantEmail">
                                    <h3>제휴 문의</h3>
                                    <p><a href="mailto:partners@ivid.kr">partners@ivid.kr</a></p>
                                </div>
                                <div className="contantEmail">
                                    <h3>투자 문의</h3>
                                    <p><a href="mailto:yoon@ivid.kr">yoon@ivid.kr</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    )
}
export default Company;

export const Style = styled.aside`
    
    * {
        margin:0;
        padding:0;
        box-sizing:border-box;
        font-weight: 400;
        font-family: 'NanumSquare';
        color: #000;
    }
    
    /* reset */
    ul,ol,li {
        text-decoration: none;
        list-style: none;
        font-family: 'NanumSquare';
    }
    p, a, h1, h2, h3, h4, h5, h6, li, a {
        transform: skew(-0.1deg);
    }
    a {
        text-decoration: none; 
        outline: none;
        color: #1A7CFF;
    }
    a:hover, a:active {
        text-decoration: none; 
    }
    
    /* container */
    .container {
        min-width: 1296px;
        max-width: 1296px;
        margin: 0 auto;
    }

    .cPage {
        margin-top: 60px;
    }
    .content {
        margin-top: 122px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        h2 {
            min-width: 270px;
            font-family: 'Poppins', sans-serif;
            font-size: 22px;
            font-weight: 700;
            letter-spacing: -1px;
        }
        p {
            font-size: 18px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: -.5px;
        }
    }
    .content:nth-child(1) {
        margin-top: 68px;
    }

    /* 연혁 */
    .hContent {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        h3 {
            margin-right: 80px;
            font-size: 18px;
            font-weight: 900;
            color: #666;
        }
    }
    .hContent:nth-child(2) {
        padding-top: 40px;
        border-top: 1px solid #c4c4c4;
    }
    .history {
        width: 100%;
        border-bottom: 2px solid #c4c4c4;
        border-top: 2px solid #c4c4c4;
        padding-top: 30px;
    }
    .hText {
        ul {
            margin-bottom: 40px;
        }
        li {
            font-size: 18px;
            font-weight: 700;
        }
        li:nth-child(1) {
            margin-bottom: 6px;
            margin-top: -4px;
            color: #666666;
        }
    }

    /* 기사 */
    .newsContent {
        width: 100%;
    }
    .news {
        padding: 50px 0;
        border-bottom: 1px solid #E8E8E8;
            
        .title { 
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            h3 {
                margin-right: 10px;
                font-size: 18px;
                font-weight: 900;
                color: #001E62;
            }
            p {
                font-size: 18px;
                font-weight: 700;
                color: #c4c4c4;
            }
            
        }
        h2 {
            width: 100%;
            font-family: 'NanumSquare';
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
        }
    }
    .news:nth-child(1) {
        padding-top: 0;
    }
    .news:hover {
        transform: scale(1.01, 1.01);
        transition-duration: 0.5s;
        transition: all 0.35s ease 0s;
    }

    /* 제휴투자업체 */
    .logoContent {
        display: flex;
        flex-direction: column;
        width: 100%;

        .logo {
            width: 100%;
            margin-bottom: 120px;
            display: flex;
            align-items:  center;
            justify-content: space-between; 
        }
        .logo:nth-child(3) {
            width: 47%;
        }
    }
    

    /* 제휴투자 문의 */
    .content:last-child {
        margin-bottom: 180px;
    }
    .contantEmail {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 33px;

        h3 {
            margin-right: 20px;
            font-size: 22px;
            font-weight: 700;
        }
        a {
           font-size: 22px;
           font-weight: 700;
           color:#1a7cff;
           border-bottom : 1px solid #1a7cff;
        }
        a:hover {
           font-weight: 800;
        }
    }

`;